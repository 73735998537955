<template>
  <v-container id="tipos-cambio" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-cash-100"
      inline
      :title="$t('exchangeRate')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Tipo de Cambio Divisas</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/ Editar tipo cambio-->
      <v-dialog max-width="500px" persistent v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Divisa -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Divisa*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.divisa.$model"
                    :error-messages="divisaErrors"
                    @blur="$v.editedItem.divisa.$touch()"
                    @input="$v.editedItem.divisa.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>

                <!-- Codigo -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Código*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.codigo.$model"
                    :error-messages="codigoErrors"
                    @blur="$v.editedItem.codigo.$touch()"
                    @input="$v.editedItem.codigo.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>

                <!-- Tipo de Cambio -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Tipo de Cambio*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.tipoCambio.$model"
                    :error-messages="tipoCambioErrors"
                    @blur="$v.editedItem.tipoCambio.$touch()"
                    @input="$v.editedItem.tipoCambio.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>

                <!-- Fecha Inicio Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          label="Válido desde*"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          :value="fromDateDisp"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="en-in"
                        no-title
                        v-model="$v.editedItem.desde.$model"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>

                <!-- Fecha Final Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          label="Válido Hasta"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          :value="toDateDisp"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="en-in"
                        no-title
                        v-model="$v.editedItem.hasta.$model"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="toDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>
                <!-- Termina campo Fecha Final Validez -->
              </v-row>
            </v-container>
            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de tipos de cambio  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="divisas"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de fechas-->
        <template v-slot:[`item.desde`]="{ item }">
          <DateFormat :begin="item.desde" :end="item.hasta" />
        </template>

        <!-- Slot de Acciones -->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="divisas" :dataFields="fields" fileName="Divisas" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import DateFormat from '@/components/general/DateFormat.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    DateFormat,
    Status,
  },
  data: () => ({
    //Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    fromDateMenu: false,
    isEditing: false,
    isLoading: false,
    isSure: false,
    minDate: new Date().toISOString().substr(0, 10),
    overlay: false,
    search: undefined,
    toDateMenu: false,
    //Arrays
    divisas: [],
    headers: [
      { text: 'Divisa', align: 'start', sortable: false, value: 'divisa' },
      { text: 'Código', value: 'codigo' },
      { text: 'Tipo de Cambio', value: 'tipoCambio' },
      { text: 'Vigencia', value: 'desde' },
      // { text: 'Fecha Final Validez', value: 'hasta' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    value: [],
    //Objetos
    defaultItem: {
      _id: '',
      divisa: '',
      codigo: '',
      tipoCambio: '',
      desde: '',
      hasta: '',
      activo: true,
    },
    editedItem: {
      _id: '',
      divisa: '',
      codigo: '',
      tipoCambio: '',
      desde: '',
      hasta: '',
      activo: true,
    },
    fields: {
      Divisa: 'divisa',
      Codigo: 'codigo',
      TipoCambio: 'tipoCambio',
      Desde: 'desde',
      Hasta: 'hasta',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      codigo: { required, minLength: minLength(3), maxLength: maxLength(20) },
      desde: { required, minLength: minLength(3), maxLength: maxLength(20) },
      divisa: { required, minLength: minLength(3), maxLength: maxLength(20) },
      hasta: { required, minLength: minLength(3), maxLength: maxLength(20) },
      tipoCambio: { required, minLength: minLength(3), maxLength: maxLength(50) },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Divisa' : 'Editar Divisa'
    },

    fromDateDisp() {
      return this.editedItem.desde
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    /**
     * @name maxDateDisp
     *
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      console.debug(year + '-' + (month < 10 ? '0' + month : month) + '-' + day)
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    toDateDisp() {
      return this.editedItem.hasta
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    // Propiedad computada para errores en campo codigo
    codigoErrors() {
      const errors = []
      if (!this.$v.editedItem.codigo.$dirty) return errors
      !this.$v.editedItem.codigo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.codigo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.codigo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo desde
    desdeErrors() {
      const errors = []
      if (!this.$v.editedItem.desde.$dirty) return errors
      !this.$v.editedItem.desde.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.desde.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.desde.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo divisa
    divisaErrors() {
      const errors = []
      if (!this.$v.editedItem.divisa.$dirty) return errors
      !this.$v.editedItem.divisa.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.divisa.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.divisa.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo hasta
    hastaErrors() {
      const errors = []
      if (!this.$v.editedItem.hasta.$dirty) return errors
      !this.$v.editedItem.hasta.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.hasta.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.hasta.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo tipo cambio
    tipoCambioErrors() {
      const errors = []
      if (!this.$v.editedItem.tipoCambio.$dirty) return errors
      !this.$v.editedItem.tipoCambio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.tipoCambio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.tipoCambio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerDivisas()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('exchangeRate/deleteExchangeRate', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de tipos de cambio
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de tipos de cambio
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de tipos de cambio
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Convierte una fecha a numeros formato 99999999
     */
    convierteFechaNumero(date) {
      const fecha = new Date(date)
      const year = fecha.getFullYear().toString()
      const month =
        fecha.getMonth().toString().length == 1
          ? '0' + (fecha.getMonth() + 1).toString()
          : fecha.getMonth().toString()
      const day =
        fecha.getDate().toString().length == 1
          ? '0' + fecha.getDate().toString()
          : fecha.getDate().toString()
      const hoy = year.toString() + month + day.toString()
      return parseFloat(hoy)
    },

    /**
     * @description Convierte un numero formato 99999999 en una fecha
     */
    convierteNumeroFecha(date) {
      if (date == 99999999) {
        return null
      }
      const cadena = date.toString()
      let year = cadena.slice(0, 4)
      let month = cadena.slice(4, 6)
      let day = cadena.slice(6, 8)
      const fecha = year + '-' + month + '-' + day

      return fecha
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.divisas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.divisas.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar tipo de cambio
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.divisas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.desde = this.convierteNumeroFecha(item.desde)
      this.editedItem.hasta = this.convierteNumeroFecha(item.hasta)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todos los boletos de ferry
     */
    async obtenerDivisas() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('exchangeRate/fetchExchangeRate', payload)
        .then((response) => {
          this.divisas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un tipo de cambio nuevo o
     * la edicion de un tipo de cambio
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const desde = this.convierteFechaNumero(this.editedItem.desde)
        const hasta = this.editedItem.hasta
          ? this.convierteFechaNumero(this.editedItem.hasta)
          : 99999999

        let body = {
          divisa: this.editedItem.divisa,
          codigo: this.editedItem.codigo,
          tipoCambio: this.editedItem.tipoCambio,
          desde: desde,
          hasta: hasta,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('exchangeRate/editExchangeRate', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          this.editedItem.desde = desde
          this.editedItem.hasta = hasta
          Object.assign(this.divisas[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('exchangeRate/addExchangeRate', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.divisas.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
